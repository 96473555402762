import 'swiper/css';

import Image from 'next/future/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { buttonVariants } from '../../UI/Button';
import { useTranslation } from 'react-i18next';
import LinkTo from '@/components/LinkTo';

export default function Awards() {
  const { t } = useTranslation();

  const section = {
    title: t('P_HOME.AWARDS.TITLE'),
    awards: [
      {
        name: 'ISPO Award',
        src: '/v2/home/awards/awards-1.png'
      },
      {
        name: 'New York Design Award',
        src: '/v2/home/awards/awards-2.png'
      },
      {
        name: 'IF Design Award',
        src: '/v2/home/awards/awards-3.png'
      },
      {
        name: 'German Design Award',
        src: '/v2/home/awards/awards-4.png'
      },
      {
        name: 'Good Design Award',
        src: '/v2/home/awards/awards-5.png'
      },
      {
        name: 'German Innovation',
        src: '/v2/home/awards/awards-6.png'
      }
    ]
  };

  return (
    <section className="section full section-y-md bg-sand-100">
      <div className="section-wrapper space-y-md">
        <h2 className="h3 text-left">{section.title}</h2>

        <div className="space-y-6 xl:flex xl:flex-row xl:space-x-16 xl:space-y-0">
          <Swiper
            wrapperTag="ul"
            slidesPerView={3}
            spaceBetween={32}
            breakpoints={{
              768: {
                slidesPerView: 6
              }
            }}
          >
            {section.awards.map((award, idx) => (
              <SwiperSlide
                key={idx}
                tag="li"
                className="flex flex-col items-center space-y-3"
              >
                <Image
                  alt={award.name}
                  src={award.src}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="w-full h-auto"
                />

                <p className="text-sm font-bold tracking-wide uppercase text-center md:text-base">
                  {award.name}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="bg-black rounded-uyn p-6 md:p-8 xl:max-w-xl">
            <h3 className="h4 text-white mb-4">{t('P_HOME.AWARDS.CTA')}</h3>

            <LinkTo
              href="/awards"
              aria-label={t('P_HOME.AWARDS.TITLE')}
              className={buttonVariants({ variant: 'secondary' })}
            >
              <span className="sr-only">{t('P_HOME.AWARDS.TITLE')}</span>
              <ArrowRightIcon className="h-5 w-5" />
            </LinkTo>
          </div>
        </div>
      </div>
    </section>
  );
}
