import React from 'react';

const GoogleTagManagerHead = () => {
  return (
    <script
      // className="_iub_cs_activate"     // Uncomment this line to enable GTAG after accepting cookies
      // type="text/plain"                // Uncomment this line to enable GTAG after accepting cookies
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_GTM}');
        `
      }}
    ></script>
  );
};

export default GoogleTagManagerHead;
