import { ApiCommonParams } from '../models/app';
import { HomeBanner, HomeSlide } from '@/types/Home';
import { getAPIClient } from '@/lib/api';
import { IProductList } from '@/models';

export interface HomeResponseData {
  layouts: {
    home_main_slider: { lang: string; sliders: HomeSlide[] }[];
    home_banner_one: HomeBanner[];
    home_banner_two: HomeBanner[];
  };
}

export const fetchHome = async ({ lang, country }: ApiCommonParams) => {
  const apiClient = getAPIClient({ country });

  const { data } = await apiClient.get<HomeResponseData>(
    '/advanced-api/api/layouts/home',
    {
      headers: {
        ['UYN-LANGUAGE']: lang,
        ['UYN-COUNTRY']: country
      }
    }
  );

  return data;
};

export interface ProductsSliderData {
  products_slider: IProductList[];
}

export const fetchProductsSlider = async ({
  lang,
  country
}: ApiCommonParams) => {
  const apiClient = getAPIClient({ country });

  try {
    const { data } = await apiClient.get<ProductsSliderData>(
      '/advanced-api/api/layouts/home-products-slider',
      {
        headers: {
          ['UYN-LANGUAGE']: lang,
          ['UYN-COUNTRY']: country
        }
      }
    );

    return data.products_slider;
  } catch (error) {
    return [];
  }
};
