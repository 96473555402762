import { useRouter } from "next/router";
import { getLangCountry, SUPPORTED_LANG } from "./languages";
import { AlternateLangs } from '../models/app';

export const useAlternateUrls = (path:string) => {

    const router = useRouter();
    const { country } = getLangCountry(router.query);

    const urls = <AlternateLangs[]>[];

    SUPPORTED_LANG.forEach((supported_lang) => {
        const lang_country = supported_lang + '_' + country;
        urls.push({
            alternateLang: supported_lang,
            url: `/${lang_country}/${path}`,
        });
    });

    return urls;
}