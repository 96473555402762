import clsx from 'clsx';
import React, { CSSProperties } from 'react';

import styles from './Skeleton.module.scss';

const toPixels = (value: string | number) => {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
};

interface SkeletonProps {
  show?: boolean;
  block?: boolean;
  className?: string;
  style?: CSSProperties;
  width?: string | number;
  height?: string | number;
  boxHeight?: string | number;
  children?: React.ReactNode;
}

const Skeleton: React.FC<SkeletonProps> = ({
  style,
  width,
  height,
  children,
  className,
  show = true,
  boxHeight = height
}) => {
  const shouldAutoSize = !!children && !(width || height);

  width = width || 24;
  height = height || 24;
  boxHeight = boxHeight || height;

  return (
    <span
      className={clsx(styles.skeleton, className, {
        [styles.show]: show,
        [styles.wrapper]: shouldAutoSize,
        [styles.loaded]: !shouldAutoSize && !!children
      })}
      style={
        shouldAutoSize
          ? {}
          : {
              minWidth: toPixels(width),
              minHeight: toPixels(height),
              marginBottom: `calc(${toPixels(boxHeight)} - ${toPixels(
                height
              )})`,
              ...style
            }
      }
    >
      {children}
    </span>
  );
};

export default Skeleton;
