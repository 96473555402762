import 'swiper/css';

import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { Autoplay } from 'swiper';

import { buttonVariants } from '../../UI/Button';
import { HomeSlide } from '@/types/Home';

import Media from '../../Media';

interface Props {
  data: HomeSlide[];
}

const Hero: React.FC<Props> = ({ data }) => {
  const [realIndex, setRealIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass>();

  return (
    <section className="relative">
      <Swiper
        onSwiper={setSwiper}
        modules={[Autoplay]}
        slidesPerView="auto"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
        onRealIndexChange={({ realIndex }) => setRealIndex(realIndex)}
      >
        {data.map((slide, idx) => {
          const isVideo = slide.vid_img === 'video_exp';

          const mediaSource = {
            mobile: {
              type: isVideo ? 'video' : 'image',
              src: isVideo ? slide.video_mobile! : slide.img_mobile,
              alt: slide.main_title
            },
            tablet: {
              type: isVideo ? 'video' : 'image',
              src: isVideo ? slide.video_tablet! : slide.img_tablet,
              alt: slide.main_title
            },
            desktop: {
              type: isVideo ? 'video' : 'image',
              src: isVideo ? slide.video_desk! : slide.img_desk,
              alt: slide.main_title
            }
          };

          return (
            <SwiperSlide
              key={idx}
              className="bg-black flex items-end aspect-hero-m md:aspect-hero-t xl:aspect-hero-d relative"
            >
              <Media source={mediaSource} />

              <div className="absolute left-4 bottom-12 md:left-16 md:bottom-16 xl:left-20 xl:bottom-36 flex flex-col justify-end space-y-3 xl:max-w-3xl">
                <div className="space-y-3 md:space-y-6">
                  <div>
                    {slide.top_title && (
                      <div
                        className={clsx(
                          'tracking-uyn uppercase md:text-xl opacity-80 mb-2 drop-shadow-md',
                          {
                            'text-white': slide.slide_skin === 'light',
                            'text-black': slide.slide_skin === 'dark'
                          }
                        )}
                      >
                        {slide.top_title}
                      </div>
                    )}

                    <h3 className="h2 flex flex-col drop-shadow-lg">
                      <span
                        className={clsx('block', {
                          'text-white': slide.slide_skin === 'light',
                          'text-black': slide.slide_skin === 'dark'
                        })}
                      >
                        {slide.main_title}
                      </span>
                    </h3>
                  </div>

                  <div className="flex flex-col ">
                    <p
                      className={clsx('text-body drop-shadow-md', {
                        'text-white': slide.slide_skin === 'light',
                        'text-black': slide.slide_skin === 'dark'
                      })}
                    >
                      {slide.description}
                    </p>
                  </div>

                  <div className="flex space-x-2 xl:space-x-2">
                    {slide.buttons.map(({ button_text, button_link }, idx) => (
                      <a
                        key={idx}
                        href={button_link}
                        aria-label={button_text}
                        className={buttonVariants({
                          variant: slide.slide_skin as any
                        })}
                      >
                        {button_text}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <ul className="absolute z-10 inset-x-0 bottom-4 xl:bottom-10 flex justify-center space-x-2">
        {data.map((_, idx) => (
          <li key={idx}>
            <button
              className={clsx('rounded-full', {
                'h-1.5 w-[15px] bg-white': realIndex === idx,
                'h-1.5 w-1.5 bg-white/60': realIndex !== idx
              })}
              onClick={() => swiper?.slideTo(idx)}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Hero;
