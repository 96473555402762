import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';
import ProductCard from './Pages/Category/Products/ProductCard';
import { IProductList } from '@/models';
import { isEmpty } from 'lodash';
import rangeMap from '@/lib/range-map';
import Skeleton from './UI/Skeleton';

export default function ProductsSection({
  isLoading,
  products
}: {
  isLoading?: boolean;
  products?: IProductList[];
}) {
  const { t } = useTranslation();

  if (!isLoading && isEmpty(products)) {
    return null;
  }

  return (
    <section className="section full section-y-md bg-sand-100 space-y-md">
      <div className="mb-12 section-wrapper">
        <div className="mx-auto text-center h3">{t('title.best_seller')}</div>
      </div>

      <Swiper
        wrapperTag="ul"
        slidesPerView={1.5}
        spaceBetween={16}
        navigation={true}
        modules={[Scrollbar, Navigation]}
        scrollbar={{ hide: false }}
        className="px-6 md:px-10 xl:px-16 swiperScrollbar"
        breakpoints={{
          768: {
            slidesPerView: 2.2
          },
          1280: {
            slidesPerView: 3.2
          },
          1536: {
            slidesPerView: 4.2
          }
        }}
      >
        {isLoading
          ? rangeMap(5, idx => (
              <SwiperSlide key={idx} tag="li">
                <Skeleton key={idx} className="w-full h-60 md:h-450 xl:h-500" />
              </SwiperSlide>
            ))
          : products?.map((product, idx) => (
              <SwiperSlide key={idx} tag="li">
                <ProductCard data={product} />
              </SwiperSlide>
            ))}
      </Swiper>
    </section>
  );
}
